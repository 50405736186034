<template>
  <div id="app">
    <Header/>
    <router-view :account-id="accountId" :template-id="templateId" :theme-color="themeColor" :terms-url="termsUrl"/>
    <Footer/>
  </div>
</template>


<script>

import Header from './Header'
import Footer from './Footer'

export default {
  props: {
    accountId: {
      type: String
    },
    templateId: {
      type: Number
    },
    themeColor: {
      type: String
    },
    termsUrl: {
      type: String
    }
  },
  components: {
    Header,
    Footer
  }
}

</script>
