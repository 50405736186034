<template>
  <div>
    <div class="header-space"></div>
    <div class="page-content-block email-alert">
      <div class="container">
        <div class="form-row">
          <div class="col-12 mb-4">
            <p>Please fill out the form below to receive company press releases via email when they
              occur.</p>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-12 col-lg-6">
            <label>First name:</label>
            <b-form-input class="form-control" v-model="form.firstName"
                          v-on:focus.native="onFocus"
                          :state="stateFirstName" aria-describedby="input-1-live-feedback"></b-form-input>
            <b-form-invalid-feedback id="input-1-live-feedback">
              {{ error.firstName }}
            </b-form-invalid-feedback>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-12 col-lg-6">
            <label>Last name:</label>
            <b-form-input class="form-control" v-model="form.lastName"
                          v-on:focus.native="onFocus"
                          :state="stateLastName" aria-describedby="input-2-live-feedback"></b-form-input>
            <b-form-invalid-feedback id="input-2-live-feedback">
              {{ error.lastName }}
            </b-form-invalid-feedback>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-12 col-lg-6">
            <label>Email:</label>
            <b-form-input type="email" class="form-control" v-model="form.email"
                          v-on:focus.native="onFocus"
                          :state="stateEmail" aria-describedby="input-3-live-feedback"></b-form-input>
            <b-form-invalid-feedback id="input-3-live-feedback">
              {{ error.email }}
            </b-form-invalid-feedback>
          </div>
        </div>
        <div class="form-row" v-if="showPhoneInput">
          <div class="form-group col-12 col-lg-6">
            <label>Phone:</label>
            <b-form-input type="text" class="form-control" :state="statePhone" v-model="form.phone"></b-form-input>
            <b-form-invalid-feedback id="input-3-live-feedback">
              {{ error.phone }}
            </b-form-invalid-feedback>
            <b-form-text id="input-live-help">Press Releases Only</b-form-text>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-12 col-lg-6">
            <label>Contact type:</label>
            <b-form-select class="form-control custom-select" v-model="form.contactType"
                           :options="contactTypeOptions"></b-form-select>
          </div>
        </div>
        <div class="form-row mt-5">
          <div class="form-group col-12">
            <h4>Choose notifications</h4>
          </div>
        </div>

        <b-form-checkbox-group v-if="showPhoneInput" id="checkbox-group-2" v-model="form.notificationMode"
                               name="flavour-2"
                               v-on:focus.native="onFocus" :state="stateMode" aria-describedby="input-33-live-feedback">
          <div class="form-row">
            <div class="form-group col-12 col-md-6 col-lg-3">
              <b-form-checkbox value="EMAIL">Email</b-form-checkbox>
            </div>
            <div class="form-group col-12 col-md-6 col-lg-3">
              <b-form-checkbox value="SMS">SMS (Press Releases Only)</b-form-checkbox>
            </div>
            <b-form-invalid-feedback id="input-33-live-feedback">
              {{ error.notificationMode }}
            </b-form-invalid-feedback>
          </div>
        </b-form-checkbox-group>

        <div class="form-row mt-5">
          <div class="form-group col-12 col-md-6 col-lg-3">
            <h5>Company information</h5>
            <div class="custom-control custom-checkbox" style="padding-left: 0px">
              <b-form-checkbox
                  id="checkbox-1"
                  v-model="allPressRelease"
                  name="checkbox-1"
                  value="true"
                  unchecked-value="false">
                All press releases
              </b-form-checkbox>
            </div>
          </div>
          <div class="form-group col-12 col-md-6 col-lg-3">
            <h5>SEC filings</h5>
            <div class="custom-control custom-checkbox" style="padding-left: 0px">
              <b-form-checkbox
                  id="checkbox-2"
                  v-model="allSecFilings"
                  name="checkbox-2"
                  value="true"
                  unchecked-value="false">
                All SEC filings
              </b-form-checkbox>
            </div>
            <div class="custom-control custom-checkbox" style="padding-left: 0px">
              <b-form-checkbox
                  id="checkbox-3"
                  v-model="annualQuarterlyReport"
                  name="checkbox-3"
                  value="true"
                  unchecked-value="false">
                Annual & quarterly reports
              </b-form-checkbox>
            </div>
            <div class="custom-control custom-checkbox" style="padding-left: 0px">
              <b-form-checkbox
                  id="checkbox-4"
                  v-model="currentReport"
                  name="checkbox-4"
                  value="true"
                  unchecked-value="false">
                Current report
              </b-form-checkbox>
            </div>
            <div class="custom-control custom-checkbox" style="padding-left: 0px">
              <b-form-checkbox
                  id="checkbox-5"
                  v-model="insiderTransaction"
                  name="checkbox-5"
                  value="true"
                  unchecked-value="false">
                Insider transactions
              </b-form-checkbox>
            </div>
            <div class="custom-control custom-checkbox" style="padding-left: 0px">
              <b-form-checkbox
                  id="checkbox-6"
                  v-model="proxyInformation"
                  name="checkbox-6"
                  value="true"
                  unchecked-value="false">
                Proxy information
              </b-form-checkbox>
            </div>
          </div>
        </div>
        <div class="form-row" style="margin-bottom: 20px">
          <div class="col-12" style="margin-left: 3px">
            <vue-recaptcha ref="recaptcha" :sitekey="siteKey"
                           @verify="onVerify" @expired="expired">
            </vue-recaptcha>
          </div>
        </div>
        <div class="form-row">
          <div class="col-12">
            <b-button :disabled="showLoader" type="submit" class="btn btn-learnMore2" @click="submit">
              <b-spinner style="margin-bottom: 3px" v-if="showLoader" small></b-spinner>
              Submit
            </b-button>
          </div>
        </div>
        <div class="form-row mt-5">
          <div class="col-12"><p>We respect your privacy. We do not sell, rent, or loan any information to any
            third party. Any information you give us is held with the utmost care and security, and will be
            used only in ways to which you have consented. </p></div>
        </div>
      </div>
    </div>
    <back-to-top text="Back to top" visibleoffset="200">
      <div class="go-up">
        <i style="color: white" class="fa fa-angle-up"></i>
      </div>
    </back-to-top>
  </div>
</template>

<style>

.go-up {
  background-color: rgba(0, 0, 0, 0.8);
  width: 40px;
  height: 40px;
  border-radius: 100%;
  text-align: center;
  line-height: 40px
}

.go-up:hover {
  background-color: rgba(50, 83, 220, 0.9)
}

</style>

<script>

import axios from 'axios'
import VueRecaptcha from 'vue-recaptcha';

export default {
  props: {
    accountId: {
      type: String
    }
  },
  watch: {
    allSecFilings(val) {
      this.annualQuarterlyReport = val;
      this.currentReport = val;
      this.insiderTransaction = val;
      this.proxyInformation = val;
    }
  },
  components: {
    VueRecaptcha
  },
  data() {
    return {
      siteKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_KEY,
      captchaVerified: false,
      showLoader: false,
      contactTypeOptions: [
        {value: 'Individual Investor', text: 'Individual Investor'},
        {value: 'Analyst', text: 'Analyst'},
        {value: 'Press/Media', text: 'Press/Media'},
        {value: 'Portfolio Manager', text: 'Portfolio Manager'},
      ],
      allPressRelease: 'false',
      allSecFilings: 'false',
      annualQuarterlyReport: 'false',
      currentReport: 'false',
      insiderTransaction: 'false',
      proxyInformation: 'false',
      form: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        contactType: 'Individual Investor',
        captchaResponse: '',
        notificationMode: ["EMAIL"]
      },
      valid: {
        firstName: true,
        lastName: true,
        email: true,
        phone: true,
        notificationMode: true,
      },
      error: {
        firstName: 'Please enter First Name',
        lastName: 'Please enter Last Name',
        email: 'Please enter valid email address',
        phone: 'Please enter valid phone number',
      },
      showPhoneInput: false
    }
  },
  computed: {
    stateFirstName() {
      if (!this.valid.firstName) {
        return false;
      }
      if (this.form.firstName.length === 0) {
        return null
      }
      return true;
    },
    stateLastName() {
      if (!this.valid.lastName) {
        return false;
      }
      if (this.form.lastName.length === 0) {
        return null
      }
      return true;
    },
    stateEmail() {
      if (!this.valid.email) {
        return false;
      }
      if (this.form.email.length === 0) {
        return null
      }
      return this.isEmailValid(this.form.email);
    },
    stateMode() {
      if (!this.valid.notificationMode) {
        return false;
      }
      return this.form.notificationMode.length !== 0;
    },
    statePhone() {
      if (this.form.notificationMode && this.form.notificationMode.indexOf("SMS") >= 0) {
        return this.form.phone.length > 0
      }
      return null;
    },
  },
  methods: {
    onFocus() {
      this.valid.firstName = true;
      this.valid.lastName = true;
      this.valid.email = true;
      this.valid.phone = true;
      this.valid.notificationMode = true;
    },
    isEmailValid(email) {
      const emailRegexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/igm
      return emailRegexp.test(email);
    },
    async submit() {
      if (!this.stateFirstName) {
        this.valid.firstName = false;
        return;
      }
      if (!this.stateLastName) {
        this.valid.lastName = false;
        return;
      }
      if (!this.stateEmail) {
        this.valid.email = false;
        return;
      }
      if (this.showPhoneInput && !this.stateMode) {
        this.valid.notificationMode = false;
        return;
      }
      if (this.showPhoneInput && this.form.notificationMode && this.form.notificationMode.indexOf("SMS") >= 0 &&
          !this.statePhone) {
        this.valid.phone = false;
        return;
      }
      if (!this.captchaVerified) {
        this.$toasted.error('Please Verify Captcha', {duration: 1500});
        return;
      }
      this.showLoader = true;
      this.form.allPressRelease = this.allPressRelease === 'true';
      this.form.allSecFilings = this.allSecFilings === 'true';
      this.form.annualQuarterlyReport = this.annualQuarterlyReport === 'true';
      this.form.currentReport = this.currentReport === 'true';
      this.form.insiderTransaction = this.insiderTransaction === 'true';
      this.form.proxyInformation = this.proxyInformation === 'true';
      await axios.post(`${this.accountId}/subscriber`, this.form);
      this.showLoader = false;
      this.$toasted.success('Subscribed Successfully', {duration: 3000});
      this.reset();
      window.scrollTo(0, 0);
    },
    reset() {
      this.allPressRelease = 'false';
      this.allSecFilings = 'false';
      this.annualQuarterlyReport = 'false';
      this.currentReport = 'false';
      this.insiderTransaction = 'false';
      this.proxyInformation = 'false';
      this.captchaVerified = false;
      this.$refs.recaptcha.reset();
      this.form = {
        firstName: '',
        lastName: '',
        email: '',
        contactType: 'Individual Investor',
        captchaResponse: '',
        phone: '',
        notificationMode: ["EMAIL"]
      };
    },
    onVerify(response) {
      this.form.captchaResponse = response;
      this.captchaVerified = true
    },
    expired() {
      this.captchaVerified = false
    },
    async isPhoneInputEnabled() {
      const {data} = await axios.get(`/account/${this.accountId}`)
      if (data && data.value) {
        this.showPhoneInput = data.value.enablePhoneInput;
      }
    }
  },
  async mounted() {
    window.scrollTo(0, 0);
    await this.isPhoneInputEnabled();
  }
}

</script>
