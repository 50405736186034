<template>
  <div id="app">
    <Navigation :account-id="accountId" :template-id="templateId" :theme-color="themeColor" :terms-url="termsUrl"/>
  </div>
</template>

<style lang="scss">
@import "assets/css/style.css";
@import "assets/css/animate.min.css";
@import "assets/css/font-awesome.min.css";
@import "assets/css/responsive.css";
</style>


<script>

import Navigation from './components/navigation/Navigation'

export default {
  props: {
    prop1: {},
    prop2: {},
    prop3: {},
    accountId: {
      type: String
    },
    templateId: {
      type: Number
    },
    themeColor: {
      type: String
    },
    termsUrl: {
      type: String
    }
  },
  components: {
    Navigation
  },
  mounted() {

  },
}

</script>
