<template>
  <div :style="cssVars">
    <a @click="toggleView" class="btn-popup-subscribe bg-theme">
      <span style="color: white">Subscribe to Investor Alerts</span>
    </a>

    <div v-if="show" class="popup-subscribe-wrapper active">
      <div class="popup-subscribe">
        <a @click="toggleView" class="btn-close-popup color-theme">
          <span v-html="closeIcon"></span>
        </a>
        <form>
          <div class="form-item">
            <p>Please fill out the form below to receive company press releases via email
              {{ showPhoneInput ? 'or SMS' : '' }} when they occur.</p>
          </div>
          <div class="form-item">
            <label>
              <h5>Name:</h5>
            </label>
            <b-form-input class="form-control" v-model="form.firstName"
                          v-on:focus.native="onFocus"
                          :state="stateFirstName" aria-describedby="input-1-live-feedback"></b-form-input>
            <b-form-invalid-feedback id="input-1-live-feedback">
              {{ error.firstName }}
            </b-form-invalid-feedback>
          </div>
          <div class="form-item">
            <label>
              <h5>Email:</h5>
            </label>
            <b-form-input class="form-control" v-model="form.email"
                          v-on:focus.native="onFocus"
                          :state="stateEmail" aria-describedby="input-1-live-feedback"></b-form-input>
            <b-form-invalid-feedback id="input-1-live-feedback">
              {{ error.email }}
            </b-form-invalid-feedback>
          </div>
          <div class="form-item" v-if="showPhoneInput">
            <label>
              <h5>Phone number:</h5>
            </label>
            <b-form-input class="form-control" v-model="form.phone"
                          v-on:focus.native="onFocus"
                          :state="statePhone" aria-describedby="input-1-live-feedback"></b-form-input>
            <b-form-invalid-feedback id="input-1-live-feedback">
              {{ error.phone }}
            </b-form-invalid-feedback>
          </div>
          <div class="form-item">
            <label>
              <input v-model="emailNotification" type="checkbox" checked>
              <span>Email Notifications</span>
            </label>
            <label v-if="showPhoneInput">
              <input v-model="smsNotification" type="checkbox">
              <span>SMS Notifications <br></span>
            </label>
            <span class="color-theme-gray">You agree to
                  <a :href="termsUrl" class="color-theme tdu">terms & privacy</a>
                  policy to receive automated messages on the email{{ showPhoneInput ? '/number' : '' }} you provide.</span>
          </div>
          <div class="form-item">
            <button class="btn-main bg-theme ttu" @click="submitForm">
              <b-spinner style="margin-bottom: 3px" v-if="showLoader" small></b-spinner>
              <span>SUBMIT FOR ALERTS</span>
            </button>
          </div>
        </form>
      </div>
    </div>

  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    accountId: {
      type: String
    },
    themeColor: {
      type: String
    },
    termsUrl: {
      type: String
    }
  },
  data() {
    return {
      show: false,
      showLoader: false,
      showPhoneInput: false,
      form: {
        firstName: '',
        lastName: '',
        email: '',
        phone: ''
      },
      emailNotification: true,
      smsNotification: true,
      valid: {
        firstName: true,
        email: true,
        phone: true
      },
      error: {
        firstName: 'Please enter name',
        email: 'Please enter valid email address',
        phone: 'Please enter valid phone number',
      },
    }
  },
  computed: {
    stateFirstName() {
      if (!this.valid.firstName) {
        return false;
      }
      if (this.form.firstName.length === 0) {
        return null
      }
      return true;
    },
    stateEmail() {
      if (!this.valid.email) {
        return false;
      }
      if (this.form.email.length === 0) {
        return null
      }
      return this.isEmailValid(this.form.email);
    },
    statePhone() {
      if (!this.showPhoneInput) {
        return true
      }
      if (!this.valid.phone) {
        return false;
      }
      if (this.form.phone.length === 0) {
        return null
      }
      return true;
    },
    cssVars() {
      return {
        '--theme-color': this.themeColor,
        '--content': this.bellIcon
      }
    },
    closeIcon() {
      return '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0,0,256,256" width="30px" height="30px">' +
          '<g fill="' + this.themeColor + '" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal">' +
          '<g transform="scale(8.53333,8.53333)">' +
          '<path d="M15,3c-6.627,0 -12,5.373 -12,12c0,6.627 5.373,12 12,12c6.627,0 12,-5.373 12,-12c0,-6.627 -5.373,-12 -12,-12zM16.414,15c0,0 3.139,3.139 3.293,3.293c0.391,0.391 0.391,1.024 0,1.414c-0.391,0.391 -1.024,0.391 -1.414,0c-0.154,-0.153 -3.293,-3.293 -3.293,-3.293c0,0 -3.139,3.139 -3.293,3.293c-0.391,0.391 -1.024,0.391 -1.414,0c-0.391,-0.391 -0.391,-1.024 0,-1.414c0.153,-0.154 3.293,-3.293 3.293,-3.293c0,0 -3.139,-3.139 -3.293,-3.293c-0.391,-0.391 -0.391,-1.024 0,-1.414c0.391,-0.391 1.024,-0.391 1.414,0c0.154,0.153 3.293,3.293 3.293,3.293c0,0 3.139,-3.139 3.293,-3.293c0.391,-0.391 1.024,-0.391 1.414,0c0.391,0.391 0.391,1.024 0,1.414c-0.153,0.154 -3.293,3.293 -3.293,3.293z">' +
          '</path>' +
          '</g>' +
          '</g>' +
          '</svg>';
    },
    bellIcon() {
      return `url('data:image/svg+xml,<svg fill="${this.themeColor}" height="32px" width="32px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 611.999 611.999" xml:space="preserve" stroke="${this.themeColor}" stroke-width="0.0061199900000000005"><g id="SVGRepo_bgCarrier" stroke-width="0"/><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/><g id="SVGRepo_iconCarrier"><g><g><g><path d="M570.107,500.254c-65.037-29.371-67.511-155.441-67.559-158.622v-84.578c0-81.402-49.742-151.399-120.427-181.203 C381.969,34,347.883,0,306.001,0c-41.883,0-75.968,34.002-76.121,75.849c-70.682,29.804-120.425,99.801-120.425,181.203v84.578 c-0.046,3.181-2.522,129.251-67.561,158.622c-7.409,3.347-11.481,11.412-9.768,19.36c1.711,7.949,8.74,13.626,16.871,13.626 h164.88c3.38,18.594,12.172,35.892,25.619,49.903c17.86,18.608,41.479,28.856,66.502,28.856 c25.025,0,48.644-10.248,66.502-28.856c13.449-14.012,22.241-31.311,25.619-49.903h164.88c8.131,0,15.159-5.676,16.872-13.626 C581.586,511.664,577.516,503.6,570.107,500.254z M484.434,439.859c6.837,20.728,16.518,41.544,30.246,58.866H97.32 c13.726-17.32,23.407-38.135,30.244-58.866H484.434z M306.001,34.515c18.945,0,34.963,12.73,39.975,30.082 c-12.912-2.678-26.282-4.09-39.975-4.09s-27.063,1.411-39.975,4.09C271.039,47.246,287.057,34.515,306.001,34.515z M143.97,341.736v-84.685c0-89.343,72.686-162.029,162.031-162.029s162.031,72.686,162.031,162.029v84.826 c0.023,2.596,0.427,29.879,7.303,63.465H136.663C143.543,371.724,143.949,344.393,143.97,341.736z M306.001,577.485 c-26.341,0-49.33-18.992-56.709-44.246h113.416C355.329,558.493,332.344,577.485,306.001,577.485z"/><path d="M306.001,119.235c-74.25,0-134.657,60.405-134.657,134.654c0,9.531,7.727,17.258,17.258,17.258 c9.531,0,17.258-7.727,17.258-17.258c0-55.217,44.923-100.139,100.142-100.139c9.531,0,17.258-7.727,17.258-17.258 C323.259,126.96,315.532,119.235,306.001,119.235z"/></g></g></g></g></svg>')`;
    }
  },
  methods: {
    isEmailValid(email) {
      const emailRegexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/igm
      return emailRegexp.test(email);
    },
    onFocus() {
      this.valid.firstName = true;
      this.valid.email = true;
      this.valid.phone = true;
    },
    toggleView() {
      this.show = !this.show
    },
    async submitForm() {
      if (!this.stateFirstName) {
        this.valid.firstName = false;
        return;
      }
      if (!this.stateEmail) {
        this.valid.email = false;
        return;
      }
      if (!this.statePhone) {
        this.valid.phone = false;
        return;
      }
      this.showLoader = true
      const payload = {
        firstName: this.form.firstName,
        lastName: "",
        email: this.form.email,
        phone: this.form.phone,
        contactType: "Individual Investor",
        notificationMode: [],
        allPressRelease: this.emailNotification || this.smsNotification,
        allSecFilings: false,
        annualQuarterlyReport: false,
        currentReport: false,
        insiderTransaction: false,
        proxyInformation: false
      }
      if (this.emailNotification) {
        payload.notificationMode.push('EMAIL')
      }
      if (this.showPhoneInput && this.smsNotification) {
        payload.notificationMode.push('SMS')
      }
      await axios.post(`${this.accountId}/subscriber`, payload);
      this.showLoader = false
      this.reset()
      this.$toasted.success('Subscribed Successfully', {duration: 3000});
      this.toggleView()
    },
    reset() {
      this.form = {
        firstName: '',
        lastName: '',
        email: '',
        phone: ''
      };
      this.emailNotification = true
      this.smsNotification = true
    },
    async isPhoneInputEnabled() {
      const {data} = await axios.get(`/account/${this.accountId}`)
      if (data && data.value) {
        this.showPhoneInput = data.value.enablePhoneInput;
      }
    }
  },
  async mounted() {
    await this.isPhoneInputEnabled()
  }
}
</script>

<style>

.btn-main, body .btn-secondary {
  padding: 22px 35px 18px;
  margin: 0 auto 0;
  font-size: 17px;
  line-height: 20px;
  min-width: 210px;
  font-weight: 400;
  width: auto;
  font-style: normal;
  color: #ffffff;
  background: var(--theme-color);
  border: none;
  text-align: center;
  position: relative;
  display: inline-block;
  border-radius: 50px;
  box-shadow: inset 0 0 20px rgba(0, 0, 0, 0);
  outline: 2px solid;
  outline-color: var(--theme-color);
  outline-offset: -1px;
  text-shadow: none;
  transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
}

.btn-main:hover, html body .btn-secondary:hover {
  opacity: 1;
  box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.15), 0 0 20px -10px var(--theme-color);
  outline-color: rgba(0, 0, 0, 0);
  outline-offset: 15px;
}

form label {
  display: block;
}

.bg-theme {
  background-color: var(--theme-color);
  color: #ffffff;
}

form input, form textarea, form select {
  display: block;
  margin: 0 0 0;
  padding: 10px 20px 10px;
  font-size: 16px;
  line-height: 20px;
  color: #333333;
  background-color: #ffffff;
  clear: both;
  outline: none;
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 3px;
}

.btn-popup-subscribe {
  padding: 43px 20px 0;
  margin: 25px 30px;
  line-height: 1.1;
  /*font-size: 20px;*/
  font-weight: 500;
  width: 135px;
  height: 135px;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 20;
  border-radius: 500px;
  border-radius: 50%;
  text-align: center;
  filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, .25));
}

.btn-popup-subscribe:before {
  padding: 0 0 0;
  margin: -15px 0 0;
  content: '';
  background: var(--content);
  font-size: 24px;
  width: 55px;
  height: 55px;
  line-height: 46px;
  border-radius: 500px;
  border-radius: 50%;
  font-family: "FontAwesome";
  text-align: center;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  border: 4px solid var(--theme-color);
  color: var(--theme-color);
  background-repeat: no-repeat;
  background-color: white;
  background-position: center;
}

.btn-popup-subscribe:hover {
  animation: shake 0.5s;
  animation-iteration-count: 1;
  opacity: 1;
  cursor: pointer;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.popup-subscribe-wrapper {
  display: block;
}

.popup-subscribe-wrapper.active .popup-subscribe {
  bottom: 0;
  opacity: 1;
}

.popup-subscribe {
  margin: 0 185px 0 0;
  width: 420px;
  padding: 35px 35px 20px;
  line-height: 1.4;
  font-size: 85%;
  border: 4px solid var(--theme-color);
  background: #ffffff;
  right: 0;
  bottom: -800px;
  position: fixed;
  z-index: 100;
  transition: all .5s ease;
  opacity: 0;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, .25);
}

.popup-subscribe label {
  padding: 0 0 0;
  margin: 0 0 5px;
}

.popup-subscribe input[type="checkbox"] {
  padding: 0 0 0;
  margin: 3px 8px 0 0;
  width: auto;
  float: left;
}

.popup-subscribe input[type="checkbox"] + span {
  padding: 0 0 0;
  margin: 0 0 0;
  font-size: 16px;
  display: block;
  overflow: hidden;
}

.popup-subscribe input[type="checkbox"] + span .color-theme-gray {
  font-size: 13px;
  display: block;
  line-height: 1.4;
}

.popup-subscribe .btn-main {
  margin: 5px 0 0;
  padding: 14px 20px 12px;
  font-size: 16px;
  line-height: 20px;
  min-width: 200px;
  font-weight: 600;
}

.popup-subscribe .form-item {
  padding: 0 0 0;
  margin: 0 0 15px;
}

body .popup-subscribe p {
  padding: 0 0 0;
}

body .popup-subscribe h5 {
  padding: 0 0 5px;
  margin: 0 0 0;
  font-size: 15px;
  font-weight: 500;
}

body .btn-close-popup {
  margin: 15px 15px;
  font-size: 25px;
  line-height: 1;
  font-weight: 100;
  width: 25px;
  height: 25px;
  background-size: contain;
  position: absolute;
  right: 0;
  top: 0;
  line-height: 1;
}

.btn-close-popup:hover {
  cursor: pointer;
}

@media screen and (max-width: 1199px) {
  .btn-popup-subscribe:before {
    padding: 0 0 0;
    margin: -20px 0 0;
    font-size: 22px;
    width: 45px;
    height: 45px;
    line-height: 40px;
    border: 2px solid var(--theme-color);
  }

  .btn-popup-subscribe {
    padding: 30px 15px 0;
    margin: 20px;
    font-size: 90%;
    width: 100px;
    height: 100px;
  }
}

@media screen and (max-width: 767px) {
  .btn-popup-subscribe:before {
    padding: 0 0 0;
    margin: -15px -5px 0;
    font-size: 18px;
    width: 35px;
    height: 35px;
    line-height: 31px;
    border: 2px solid var(--theme-color);
  }

  .btn-popup-subscribe {
    padding: 20px 8px 0;
    margin: 50px 10px;
    font-size: 10px;
    width: 70px;
    height: 70px;
  }

  .popup-subscribe {
    margin: 0 0 0 0;
    width: 100%;
    font-size: 95%;
    padding: 35px 35px 25px;
    max-width: 450px;
    border: 2px solid var(--theme-color);
  }
}
</style>