<template>
  <div>
    <Template1 v-if="!templateId || templateId===1" :account-id="accountId"/>
    <Template2 v-if="templateId===2" :account-id="accountId" :theme-color="themeColor" :terms-url="termsUrl"/>
  </div>
</template>

<style>

</style>

<script>

import Template1 from "@/components/template1/index.vue";
import Template2 from "@/components/template2/index.vue";

export default {
  props: {
    accountId: {
      type: String
    },
    templateId: {
      type: Number
    },
    themeColor: {
      type: String
    },
    termsUrl: {
      type: String
    }
  },
  components: {
    Template1,
    Template2
  }
}

</script>
